<template>
  <div ref="container" class="container"></div>
  <div class="sidebar">
      <div class="scene" 
        v-for="(item) in roomInfo" 
        :class="{ active: currentTag.id === item.id }" 
        @click="handleClickSide(item.en_name)">{{ item.name }}
      </div>
  </div>
  <div class="map-container">
    <img :src="map" class="map" alt="地图">
    <img ref="currentTagEl" :src="mapLocation" class="mapLocation" alt="当前位置标记">
  </div>
  <div class="loading" v-if="progress < 100">
    <img :src="loadingImg" alt="loading" class="loading-img">
    <div class="progress">加载中: {{ progress }}%</div>
  </div>
</template>

<script setup>
import { ref, onMounted, reactive } from "vue"
import * as THREE from "three"
// 导入gsap, 它是一个动画库, 用于控制相机的移动, 旋转等
import { gsap } from "gsap"
import { debounce } from 'lodash'
import Hammer from 'hammerjs'
import map from '@/assets/imgs/map.gif'
import mapLocation from '@/assets/imgs/map-location.png'
import loadingImg from '@/assets/imgs/loading.png'

const progress = ref(0)
// 加载进度
const onLoadingProgress = () => {
  THREE.DefaultLoadingManager.onProgress = ( url, itemsLoaded, itemsTotal ) => {
    progress.value = ((itemsLoaded / itemsTotal)*100).toFixed(2)
  }
}
onLoadingProgress()

// 创建场景
const scene = new THREE.Scene()
// 创建相机
const camera = new THREE.PerspectiveCamera(
  95,
  window.innerWidth / window.innerHeight,
  0.1,
  1000
)
camera.position.set(0, 0, 0)
scene.add(camera)

// 创建渲染器, 并设置抗锯齿
const renderer = new THREE.WebGLRenderer({ antialias: true });
// 设置渲染器的像素比, 解决高清屏下模糊的问题
renderer.setPixelRatio(window.devicePixelRatio);
renderer.setSize(window.innerWidth, window.innerHeight)
const container = ref(null)

const render = () => {
  requestAnimationFrame(render)
  renderer.render(scene, camera)
}

const addMouseListener = () => {
  // 创建手势监听器，并绑定到容器上
  const hammer = new Hammer(container.value)
  // 设置手势监听器的方向，这里设置为所有方向
  hammer.get('pan').set({ direction: Hammer.DIRECTION_ALL })
  // 监听手势
  hammer.on('pan', (e) => {
    // 根据不同设备设置旋转速度
    const rotationSpeed = e.pointerType === 'mouse' ? 0.01 : 0.1

    camera.rotation.y += e.velocityX * rotationSpeed
    camera.rotation.x += e.velocityY * rotationSpeed
    // 设置摄像机旋转顺序，首先应用Y轴旋转，然后是X轴旋转，最后是Z轴旋转。
    camera.rotation.order = "YXZ"
  })
}

/**
 * 创建房间
 * @param {String} name 房间名称
 * @param {String} index 房间图片索引
 * @param {String} textureUrl 房间图片路径
 * @param {THREE.Vector3} position 房间位置
 * @param {THREE.Euler} rotation 房间旋转
 */
class Room {
  constructor(
    name,
    index,
    textureUrl,
    position = new THREE.Vector3(0, 0, 0),
    euler = new THREE.Euler(0, 0, 0)
  ) {
    this.name = name
    // 创建立方体
    const size = 10
    const geometry = new THREE.BoxGeometry(size, size, size)
    // 对创建的立方体进行缩放，x,y上保持原始大小，z轴上翻转，可以在立方体的内部看到立方体六个面
    geometry.scale(1, 1, -1)

    // 创建纹理加载器
    const textureLoader = new THREE.TextureLoader()
    // 导入六个面的纹理, 顺序，左右上下后前
    const arr = [
      `${textureUrl}/${index}_l`,
      `${textureUrl}/${index}_r`,
      `${textureUrl}/${index}_u`,
      `${textureUrl}/${index}_d`,
      `${textureUrl}/${index}_b`,
      `${textureUrl}/${index}_f`,
    ]
    // 创建材质数组
    const boxMaterials = []
    // 导入6个面的纹理，创建材质
    arr.forEach((item) => {
      // 导入纹理
      const texture = textureLoader.load(require(`${item}.jpg`))
      // 翻转纹理
      if (
        item === `${textureUrl}/${index}_u` ||
        item === `${textureUrl}/${index}_d`
      ) {
        // 纹理将围绕其中心点（0.5, 0.5）旋转180度
        texture.center = new THREE.Vector2(0.5, 0.5)
        texture.rotation = Math.PI
      }
      // 创建材质
      const material = new THREE.MeshBasicMaterial({ map: texture })
      // 将材质添加到数组中
      boxMaterials.push(material)
    })
    // 创建立方体
    const cube = new THREE.Mesh(geometry, boxMaterials)
    cube.position.copy(position)
    cube.rotation.copy(euler)
    scene.add(cube)
  }
}

/**
 * 创建精灵
 * @param {String} text 文字
 * @param {THREE.Vector3} position 位置
 */
class SpriteText {
  constructor(text = '精灵指引', position = new THREE.Vector3(0, 0, 0)) {
    this.callbacks = []

    // 创建画布
    const canvas = document.createElement("canvas")
    // 设置画布宽高
    canvas.width = 1024
    canvas.height = 1024
    // 获取画布上下文
    const context = canvas.getContext("2d")
    // 绘制背景
    context.fillStyle = "rgba(100, 100, 100, 0.5)"
    context.fillRect(0, 256, 1024, 512)

    // 绘制文字
    context.textAlign = "center"
    context.textBaseline = "middle"
    context.font = "bold 200px Arial"
    context.fillStyle = "white"
    // 在中心绘制文字
    context.fillText(text, 512, 512)
    const texture = new THREE.CanvasTexture(canvas)
    const spriteMaterial = new THREE.SpriteMaterial({ 
      map: texture,
      transparent: true,
   })
    const sprite = new THREE.Sprite(spriteMaterial)
    sprite.position.copy(position)
    const spriteScale = 0.6
    sprite.scale.set(spriteScale, spriteScale, spriteScale)
    this.sprite = sprite
    scene.add(sprite)
    
    // 创建鼠标位置
    const mouse = new THREE.Vector2()
    // 创建射线
    const raycaster = new THREE.Raycaster()
    // 添加点击事件
    window.addEventListener("click", (e) => {
      e.preventDefault()
      // 获取鼠标点击位置的三维坐标
      mouse.x = (e.clientX / window.innerWidth) * 2 - 1
      mouse.y = -(e.clientY / window.innerHeight) * 2 + 1
      // 通过鼠标点的位置和当前相机的矩阵计算出raycaster
      raycaster.setFromCamera(mouse, camera)
      // 获取raycaster直线和所有模型相交的数组集合
      const intersects = raycaster.intersectObjects([sprite])
      // 判断是否点击到当前精灵
      if (intersects.length > 0) {
        this.callbacks.forEach((callback) => {
          callback()
        })
      }
    })
  }
  onClick(callback) {
    this.callbacks.push(callback)
  }
}

const resize = () => {
  // 页面尺寸变化时，使用防抖函数控制重新渲染的频率
  const debounceRender = debounce(() => {
    // 获取页面宽高
    const { clientWidth, clientHeight } = document.documentElement
    // 设置渲染器宽高
    renderer.setSize(clientWidth, clientHeight)
    // 设置渲染器像素比, 解决移动端模糊问题
    renderer.setPixelRatio(window.devicePixelRatio)
    // 设置相机宽高比
    camera.aspect = clientWidth / clientHeight
    // 更新相机投影矩阵
    camera.updateProjectionMatrix()
  }, 300)
  window.addEventListener("resize", debounceRender)
}

const currentTagEl = ref(null)
const currentTag = reactive({
  position: [],
  currentTagEl,
  id: '',
})
const LIVING_KEY = "living" // 客厅
const KITCHEN_KEY = "kitchen" // 厨房
const BALCONY_KEY = "balcony" // 阳台

const roomInfo = {
  [`${LIVING_KEY}`]: {
    name: "客厅",
    en_name: LIVING_KEY,
    position: [0, 0, 0],
    euler: [0, 0, 0],
    id: 0,
    index: '0',
    textureUrl: './assets/imgs/living',
  },
  [`${KITCHEN_KEY}`]: {
    name: "厨房",
    en_name: KITCHEN_KEY,
    position: [-5, 0, -10],
    euler: [0, -Math.PI / 2, 0],
    id: 1,
    index: '3',
    textureUrl: './assets/imgs/kitchen',
  },
  [`${BALCONY_KEY}`]: {
    name: "阳台",
    en_name: BALCONY_KEY,
    position: [10, 0, 0],
    euler: [0, Math.PI / 2, 0],
    id: 2,
    index: '8',
    textureUrl: './assets/imgs/balcony',
  },
}

const changeTag = (toRoom) => {
  // 缩略图标签
  const tagRoomMapper = {
    [`${LIVING_KEY}`]: { id: roomInfo[`${LIVING_KEY}`].id , position: [100, -120] },
    [`${KITCHEN_KEY}`]: { id: roomInfo[`${KITCHEN_KEY}`].id, position: [180, -64] },
    [`${BALCONY_KEY}`]: { id: roomInfo[`${BALCONY_KEY}`].id, position: [35, -190] },
  }
  if(tagRoomMapper[toRoom]) {
    const position = tagRoomMapper[toRoom].position
    currentTag.position = position
    currentTag.id = tagRoomMapper[toRoom].id
    console.log('currentTag', currentTag.id)
    gsap.to(currentTagEl.value, {
      x: position[0],
      y: position[1],
      duration: 0.5,
    })
  }
}

const handleClickSide = (toRoom) => {
  changeTag(toRoom)
  gsap.to(camera.position, {
    x: roomInfo[toRoom].position[0],
    y: roomInfo[toRoom].position[1],
    z: roomInfo[toRoom].position[2],
    duration: 1,
  })
}

onMounted(() => {
  container.value.appendChild(renderer.domElement)

  // 创建客厅
  const livingRoomInfo = roomInfo[LIVING_KEY]
  const livingRoomPosition = new THREE.Vector3(...livingRoomInfo.position)
  const livingRoom = new Room(livingRoomInfo.name, livingRoomInfo.index, livingRoomInfo.textureUrl, livingRoomPosition)
  changeTag(LIVING_KEY)
  // 创建厨房
  const kitchenInfo = roomInfo[KITCHEN_KEY]
  const kitchenPosition = new THREE.Vector3(...kitchenInfo.position)
  const kichenEuler = new THREE.Euler(...kitchenInfo.euler)
  const kitchen = new Room(kitchenInfo.name, kitchenInfo.index, kitchenInfo.textureUrl, kitchenPosition, kichenEuler)
  // 创建客厅->厨房的精灵
  const goKichenTextPosition = new THREE.Vector3(-1.5, 0, -4)
  const goKichenText = new SpriteText('去厨房', goKichenTextPosition)
  // 点击去厨房的精灵，切换到厨房
  goKichenText.onClick(() => {
    // 将相机切换到厨房
    gsap.to(camera.position, {
      x: kitchenPosition.x,
      y: kitchenPosition.y,
      z: kitchenPosition.z,
      ease: "power2.inOut"
    })
    changeTag(KITCHEN_KEY)
  })

  // 厨房->客厅的精灵
  const kitchenBackLivingTextPosition = new THREE.Vector3(-4, 0, -6)
  const kitchenBackLivingText = new SpriteText('回客厅', kitchenBackLivingTextPosition)
  kitchenBackLivingText.onClick(() => {
    // 将相机切换到客厅
    gsap.to(camera.position, {
      x: livingRoomPosition.x,
      y: livingRoomPosition.y,
      z: livingRoomPosition.z,
      ease: "power2.inOut"
    })
    changeTag(LIVING_KEY)
  })
  // 创建阳台
  const balconyInfo = roomInfo[BALCONY_KEY]
  const balconyPosition = new THREE.Vector3(...balconyInfo.position)
  const balconyEuler = new THREE.Euler(...balconyInfo.euler)
  const balcony = new Room(balconyInfo.name, balconyInfo.index, balconyInfo.textureUrl, balconyPosition, balconyEuler)
  // 创建客厅->阳台的精灵
  const balconyTextPosition = new THREE.Vector3(0, 0, 4)
  const balconyText = new SpriteText('去阳台', balconyTextPosition)
  balconyText.onClick(() => {
    gsap.to(camera.position, {
      x: balconyPosition.x,
      y: balconyPosition.y,
      z: balconyPosition.z,
      ease: "power2.inOut"
    })
    changeTag(BALCONY_KEY)
  })
  // 创建阳台->客厅的精灵
  const balconyBackToLiving = new THREE.Vector3(7, 0, 0)
  const balconyBackToSpriteText = new SpriteText('回客厅', balconyBackToLiving)
  balconyBackToSpriteText.onClick(() => {
    // 将相机切换到客厅
    gsap.to(camera.position, {
      x: livingRoomPosition.x,
      y: livingRoomPosition.y,
      z: livingRoomPosition.z,
      ease: "power2.inOut"
    })
    changeTag(LIVING_KEY)
  })
  addMouseListener()
  render()
  resize()
})
</script>

<style lang="scss" scoped>
*, html, body {
  margin: 0;
  padding: 0;
}

.container {
  height: 100vh;
  width: 100vw;
  background-color: #f0f0f0;
  overflow: hidden;
}
.map-container {
  width: 300px;
  position: absolute;
  bottom: 0;
  left: 0;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 0 10px #00000080;

  .map {
    width: 100%;
    height: 100%;
  }
  .mapLocation {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 30px;
    height: auto;
  }
}
// 非pc端隐藏地图
@media screen and (max-width: 1200px) {
  .map-container {
    display: none;
  }
}

.sidebar {
  position: absolute;
  left: 10px;
  top: 100px;
  z-index: 10;
  .scene {
    color: #f0f0f0;
    background-color: rgba(17,30,54,.7);
    border-radius: 4px;
    border: 2px solid transparent;
    font-size: 12px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    width: 72px;
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }
    &.active,
    &:hover {
      color: #25c9ff;
      border: 2px solid #25c9ff;
    }
    &:hover {
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 768px) {
  .sidebar {
    left: 5px;
    top: 20px;
    .scene {
      height: 24px;
      line-height: 24px;
      width: 50px;
    }
  }
}

.loading {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background: rgba(255, 255, 255, .8);
    left: 0;
    top: 0;
    z-index: 20;
    overflow: hidden;
    .loading-img {
      width: 100%;
      height: 100%;
      filter: blur(20px);
    }
    .progress {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 10;
      padding: 5px 20px;
      border-radius: 4px;
      font-size: 16px;
      background-color: rgba(255, 255, 255, .8);
    }

}
</style>
